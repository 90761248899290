<template>
	<div>
		<CRow>
			<CCol sm="12">
				<AddEditForm :headerTitle="$t('pages.agent.editAgent')" :onGoBack="onGoBack" :showDelete="false">
					<template v-slot:headerAddEdit>
						<button type="button" name="edit-editAgent" class="btn btn-primary" @click="onSave">
							{{ $t('common.save') }}
						</button>
					</template>
					<template #content>
						<Accordion :headerTitle="$t('common.generalInfo')" v-if="editingAgent != null">
							<template v-slot:body>
								<CForm>
									<CInput :placeholder="$t('pages.agent.enterAgentName')" v-model="editingAgent.name" :is-valid="validator" horizontal>
										<template #label>
											<div class="col-sm-3 col-form-label">
												{{ $t('common.name') }}
												<small class="requiredInput">(*)</small>
											</div>
										</template>
									</CInput>
									<CInput :label="$t('common.phone')" :placeholder="$t('common.enterPhoneNumber')" v-model="editingAgent.phone"
											@keypress="$func.onlyNumbers" horizontal>
									</CInput>
									<CInput :label="$t('common.address')" :placeholder="$t('pages.agent.enterAddress')" v-model="editingAgent.address" horizontal></CInput>

									<div role="group" class="form-group form-row">
										<label class="col-sm-3 col-form-label">{{ $t('pages.agent.ownerName') }}</label>
										<div class="col-sm-9 input-group">
											<select class="form-control" v-model="editingAgent.ownerId">
												<option selected="selected" value="0">{{ $t('pages.agent.selectOwnerName') }}</option>
												<option v-for="(item) in agentOwnerUserList" v-bind:key="item.id" v-bind:value="item.id">
													{{item.userName}}
												</option>
											</select>
										</div>
									</div>									

									<div role="group" class="form-group form-row">
										<label class="col-sm-3 col-form-label">{{ $t('pages.agent.selectProvince') }}</label>
										<div class="col-sm-9 input-group">
											<select class="form-control" v-model="editingAgent.provinceId" @change="onChangeProvince($event)">
												<option selected="selected" value="">{{ $t('pages.agent.selectProvince') }}</option>
												<option v-for="(item) in listProvinces" v-bind:key="item.provinceId" v-bind:value="item.provinceId">
													{{item.name}}
												</option>
											</select>
										</div>
									</div>
									<div role="group" class="form-group form-row">
										<label class="col-sm-3 col-form-label">{{ $t('pages.agent.selectDistrict') }}</label>
										<div class="col-sm-9 input-group">
											<select class="form-control" v-model="editingAgent.districtId" @change="onChangeDistrict($event)">
												<option selected="selected" value="">{{ $t('pages.agent.selectDistrict') }}</option>
												<option v-for="(item) in listDistricts" v-bind:key="item.districtId" v-bind:value="item.districtId">
													{{item.name}}
												</option>
											</select>
										</div>
									</div>
									<div role="group" class="form-group form-row">
										<label class="col-sm-3 col-form-label">{{ $t('pages.agent.selectWard') }}</label>
										<div class="col-sm-9 input-group">
											<select class="form-control" v-model="editingAgent.wardId">
												<option selected="selected" value="">{{ $t('pages.agent.selectWard') }}</option>
												<option v-for="(item) in listWards" v-bind:key="item.wardId" v-bind:value="item.wardId">
													{{item.name}}
												</option>
											</select>
										</div>
									</div>

									<CInput :label="$t('common.subject')" :placeholder="$t('pages.agent.enterSubject')" v-model="editingAgent.subject" horizontal></CInput>
									<div role="group" class="form-group form-row">
										<label class="col-sm-3 col-form-label">
											{{ $t('common.description') }}
										</label>
										<div class="col-sm-9 input-group">
											<ckeditor v-model="editingAgent.description" :config="editorConfig"></ckeditor>
										</div>
									</div>
								</CForm>
							</template>
						</Accordion>
					</template>
				</AddEditForm>
			</CCol>
		</CRow>
		<!-- Show product images -->
		<CRow>
			<CCol sm="12">
				<Accordion :headerTitle="$t('pages.agent.agentPictures')" v-if="editingAgent != null" expand>
					<template v-slot:body>
						<AgentImageList :agentId="editingAgent.id" :items="editingAgent.pictures" :onDelete="onDeletePicture" :onUploadImagesSuccess="onUploadImagesSuccess"></AgentImageList>
					</template>
				</Accordion>

				<Confirmation ref="confirmation"></Confirmation>
			</CCol>
		</CRow>
	</div>
	
</template>

<script>
    import i18n from '@/plugins/i18n'
	import { mapGetters } from 'vuex'
    import { globalConfigs } from '@/global/index'
    import Accordion from '@/components/Accordion.vue'
	import AddEditForm from '@/components/AddEditForm.vue'
	import AgentImageList from '@/components/AgentImageList.vue'
    import Confirmation from '@/components/Confirmation.vue'

	export default {
		name: 'EditAgent',
		data() {
			return {
				horizontal: { label: 'col-3', input: 'col-9' },
				editorConfig: globalConfigs.CKEditorConfig,
                files: null,
			}
		},
        computed: {
            ...mapGetters('agent', ['allAgents', 'editingAgent', 'hasError', 'errorMessage']),
			...mapGetters('location', ['listProvinces', 'listDistricts', 'listWards']),
            ...mapGetters('account', ['agentOwnerUserList']),
		},
		components: {
			Accordion,
			AddEditForm,
			AgentImageList,
            Confirmation
        },
		methods: {
            onSave() {
                this.onUpdateAgent();
            },
            onGoBack() {
                this.$router.push('/agents/list');
            },
            validator(val) {
                return val ? val.length > 0 : false
            },			
            async onUpdateAgent() {
				if(!this.validator(this.editingAgent.name))
				{					
                    this.$store.dispatch('alert/error', this.$t('pages.agent.agentNameisInvalid'));					
					return;
				}
												
				var retVal = await this.$store.dispatch("agent/saveAgent");
				if (retVal == true) {
                    this.onGoBack();
                }
			},
			async onChangeProvince(event) {				
				await this.$store.dispatch("location/getDistrictsByProviceId", event.target.value);
			},
			async onChangeDistrict(event) {				
				await this.$store.dispatch("location/getWardsByDistrictId", event.target.value);
			},
            async handleImages(files) {
                this.files = files;
            },
            async onUploadImagesSuccess() {
                this.$store.dispatch('toast/displayNotification', { text: i18n.t('pages.account.noticesUploadimage') }, { root: true });
            },
            async onDeletePicture(item) {
                this.$refs.confirmation.show(i18n.t("pages.product.confirmDeletePicture"), async () => {
                    console.log('start deleting item', item)
                    var retVal = await this.$store.dispatch("agent/deleteAgentImages", item.id);
                });
            },
		},
		mounted() {			
            this.$store.dispatch("agent/editAgent", this.$route.params.id);
            this.$store.dispatch("account/getAgentOwnerUserList", this.$store.state.auth.user.id);
		}
	}
</script>